import { get } from "lodash";
import { Dispatch } from "redux";

import ufc from "@tvg/api/ufc";
import mediator from "@tvg/mediator";
import * as mediatorClassic from "@tvg/mediator-classic/src";
import type { Module } from "@urp/amplitude/src/modules/raceCell/types";
import {
  setFavoriteTrack,
  removeFavoriteTrack,
  setUserFavoriteTracksWithId
} from "@tvg/shared-actions/UserActions";
import { getUserCompliance } from "@urp/unified-modules/src/utils/account/getUserCompliance";
import { getSession } from "@urp/unified-modules/src/utils/account";
import { events as AlchemerEvents } from "@urp/alchemer";
import { isFDR } from "@tvg/utils/generalUtils";

import { setUserFavoriteTracks } from "../redux/actions/userFavorites";
import { FavoriteWithIdTracks, Favorite } from "../types";
import { sendFavoriteAnalyticEvt } from "./analytics";

export const fetchUserFavoriteTracks = async (
  accountNumber: string,
  dispatch: Dispatch
) => {
  const favoriteTracksWithId: FavoriteWithIdTracks = {};
  const favoriteTracks: string[] = [];
  try {
    const { data } = await ufc.getUserAlerts(accountNumber, "track");
    const ufcFavoriteTracks = await data.favorites;
    ufcFavoriteTracks.forEach((favorite: Favorite) => {
      favoriteTracksWithId[favorite.entityName] = favorite.favoriteId;
      favoriteTracks.push(favorite.entityName);
    });
    dispatch(setUserFavoriteTracksWithId(favoriteTracksWithId));
    dispatch(setUserFavoriteTracks(favoriteTracks));
  } catch (e) {
    console.error("Error while fetching");
  }
};

export const updateFavoritesSuccess = (
  dispatch: Dispatch,
  trackCode: string,
  accountNumber?: string
) => {
  if (typeof accountNumber === "string") {
    addTrackToFavorites(dispatch, trackCode, accountNumber);
  }
};

export const updateFavorites = async (
  dispatch: Dispatch,
  trackCode: string,
  trackName: string,
  favoriteId: number | null,
  userData: {
    isLogged: boolean;
    accountNumber: string;
    isAccountCompliant?: boolean;
  },
  analytics: { module: Module; activeTab: string }
) => {
  const { isLogged, accountNumber, isAccountCompliant } = userData;
  const { module, activeTab } = analytics;
  if (isLogged) {
    if (!isAccountCompliant) {
      getUserCompliance();
      return;
    }

    if (favoriteId) {
      await removeTrackFromFavorites(
        dispatch,
        trackCode,
        accountNumber,
        favoriteId
      );
    } else {
      await addTrackToFavorites(dispatch, trackCode, accountNumber);
      AlchemerEvents.setTrackAsFavorite();
    }
  } else if (isFDR()) {
    getSession("Normal", ({ accountNumber: localAccountNumber }) => {
      updateFavoritesSuccess(dispatch, trackCode, localAccountNumber);
    });
  } else {
    mediator.base.dispatch({
      type: "OPEN_LOGIN",
      payload: {
        callback: (error: Error | null, success: Object) => {
          if (!error && success) {
            const localAccountNumber = get(
              success,
              "data.userDetails.accountNumber",
              null
            );

            updateFavoritesSuccess(dispatch, trackCode, localAccountNumber);
          }
        },
        triggerAction: "tracks_favorite_change"
      }
    });
  }

  sendFavoriteAnalyticEvt({
    isFavoriting: !favoriteId,
    trackName,
    module,
    activeTab
  });
};

export const addTrackToFavorites = async (
  dispatch: Dispatch,
  trackCode: string,
  accountNumber: string
) => {
  try {
    const { data } = await ufc.addFavorite(accountNumber, "track", [trackCode]);
    const { favoriteId } = data[0];
    dispatch(setFavoriteTrack(trackCode, favoriteId));
    updateSessionFavoriteTracks(true, trackCode, favoriteId);
  } catch (error) {
    console.log("Error while adding favorite");
  }
};

export const removeTrackFromFavorites = async (
  dispatch: Dispatch,
  trackCode: string,
  accountNumber: string,
  favoriteId: number | null
) => {
  try {
    await ufc.removeFavorite(accountNumber, favoriteId);
    dispatch(removeFavoriteTrack(trackCode));
    updateSessionFavoriteTracks(false, trackCode, favoriteId);
  } catch (error) {
    console.log("Error while removing favorite");
  }
};

export const updateSessionFavoriteTracks = (
  isAddingFavorite: boolean,
  trackCode: string,
  favoriteId: number | null
) => {
  const favorites = (sessionStorage.getItem("favoriteTracks") || "").split(",");
  if (isAddingFavorite) {
    if (!favorites.includes(trackCode)) {
      favorites.push(trackCode);
    }
  } else {
    favorites.splice(favorites.indexOf(trackCode), 1);
  }
  mediator.base.dispatch({
    type: "UPDATE_SESSION_FAVORITE_TRACKS",
    payload: {
      isAddingFavorite,
      trackCode,
      favoriteId
    }
  });

  // TVG5: integration with legacy code
  mediatorClassic.dispatch("UPDATE_FAVORITE_TRACKS", {
    favorites,
    trackCode,
    favoriteId
  });
};
