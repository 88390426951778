import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { noop } from "lodash";
import type { RaceStatusEnum, RaceTypeCodeEnum } from "@tvg/ts-types/Race";
import { WagerProfile } from "@tvg/ts-types/User";
import { getPortByBrand } from "@tvg/utils/generalUtils";
import { GET_TODAYS_RACES } from "../graphql/queries/TodayRacesQuery";
import { GET_TODAYS_RACES_UPDATE } from "../graphql/queries/TodayRacesSubscription";
import {
  apolloOptionsTodayRaces,
  getTodayRacesProps
} from "../graphql/options.graph";
import { FilterOptions } from "../types";

interface Props extends FilterOptions {
  wagerProfile?: WagerProfile;
  tracksFilter?: Array<string>;
  raceTypesFilter?: Array<RaceTypeCodeEnum>;
  raceStatusFilter?: Array<RaceStatusEnum>;
  uniqByTrack?: boolean;
  callSubscribeToMore?: boolean;
}

export default ({
  wagerProfile = getPortByBrand(),
  tracksFilter = [],
  raceTypesFilter = [],
  raceStatusFilter = [],
  regionsFilter = [],
  distancesFilter = [],
  racesFilter = [],
  uniqByTrack = false,
  callSubscribeToMore = false
}: Props) => {
  const { loading, data, subscribeToMore } = useQuery(
    GET_TODAYS_RACES,
    apolloOptionsTodayRaces({ wagerProfile })
  );

  useEffect(() => {
    let unSubscribe: Function = noop;
    if (callSubscribeToMore) {
      const { variables } = apolloOptionsTodayRaces({ wagerProfile });
      unSubscribe = subscribeToMore({
        document: GET_TODAYS_RACES_UPDATE,
        variables
      });
    }

    return () => {
      if (typeof unSubscribe === "function") unSubscribe();
    };
  }, [callSubscribeToMore, subscribeToMore, wagerProfile]);

  return {
    loading,
    ...getTodayRacesProps(data, {
      tracksFilter,
      uniqByTrack,
      raceStatusFilter,
      raceTypesFilter,
      racesFilter,
      regionsFilter,
      distancesFilter
    })
  };
};
