import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery, useMutation } from "@apollo/client";
import { getAccountNumber, getIsLogged } from "@urp/store-selectors";
import { PromotionTypeEnum } from "@tvg/ts-types/Promos";
import type { Promo } from "@tvg/ts-types/Race";
import { isFdrProduct } from "@tvg/sh-utils/mobileUtils";
import {
  GET_USER_OPTIN_PROMOS,
  GET_USER_PROMOS
} from "../graphql/queries/UserPromosQuery";
import {
  apolloOptionsUserOptinPromos,
  apolloOptionsUserPromos
} from "../graphql/options.graph";
import { OPTIN_USER_ON_PROMO } from "../graphql/mutations/UserPromosMutation";
import type { ApolloUserOptinPromotions, ApolloUserPromotions } from "../types";
import {
  setUserEligiblePromotions,
  setUserOptedinPromotions,
  setUserPromotions
} from "../redux/actions/userPromos";

const SOURCE = isFdrProduct() ? "FDR" : "TVG";

const useUserPromos = (promotionType = PromotionTypeEnum.ALL) => {
  const dispatch = useDispatch();

  // SELECTORS
  const accountNumber = useSelector(getAccountNumber);
  const isUserLogged = useSelector(getIsLogged);

  // QUERIES
  const {
    data: userPromotionsData,
    loading: userPromotionsLoading,
    error: userPromotionsError
  } = useQuery<ApolloUserPromotions>(
    GET_USER_PROMOS,
    apolloOptionsUserPromos(accountNumber, isUserLogged, promotionType)
  );
  const {
    data: userOptinData,
    loading: userOptinPromotionsLoading,
    error: userOptinPromotionsError
  } = useQuery<ApolloUserOptinPromotions>(
    GET_USER_OPTIN_PROMOS,
    apolloOptionsUserOptinPromos(accountNumber, isUserLogged)
  );

  // MUTATION
  const [optinUser] = useMutation(OPTIN_USER_ON_PROMO, {
    refetchQueries: [
      { query: GET_USER_PROMOS },
      { query: GET_USER_OPTIN_PROMOS }
    ]
  });

  const loading = userPromotionsLoading || userOptinPromotionsLoading;

  const promotions = userPromotionsData?.userPromotions?.promotions || [];
  const eligiblePromotions =
    userPromotionsData?.userPromotions?.eligiblePromotions || [];
  const optedInPromotions = userOptinData?.userOptInPromotions || [];

  const addUserToPromo = (promotionId: number, promotionCodeId: number) =>
    optinUser({
      variables: {
        accountNumber: +accountNumber,
        promotionId,
        promotionCodeId,
        optInDate: new Date().toISOString(),
        source: SOURCE
      }
    });

  useEffect(() => {
    if (userPromotionsData) {
      const response = userPromotionsData?.userPromotions;

      if (response?.eligiblePromotions) {
        dispatch(setUserEligiblePromotions(response.eligiblePromotions));
      }
      if (response?.promotions) {
        dispatch(setUserPromotions(response.promotions));
      }
    }
  }, [JSON.stringify(userPromotionsData)]);

  useEffect(() => {
    if (userOptinData) {
      const response = userOptinData?.userOptInPromotions;

      if (response) {
        dispatch(setUserOptedinPromotions(response));
      }
    }
  }, [JSON.stringify(userOptinData)]);

  const checkRacePromos = (racePromos: Promo[] = []) =>
    racePromos.reduce(
      (acc, racePromo) => {
        const promoAvailable = promotions.find(
          (promo) => promo.id === racePromo.rootParentPromoID
        );

        if (
          (!racePromo?.isAboveTheLine && isUserLogged) ||
          racePromo.isAboveTheLine
        ) {
          acc.hasPromoAvailable = true;
        }
        if (promoAvailable && promoAvailable.optedIn)
          acc.isOptedInPromo = promoAvailable.optedIn;

        return acc;
      },
      { hasPromoAvailable: false, isOptedInPromo: false }
    );

  return {
    promotions,
    eligiblePromotions,
    optedInPromotions,
    loading,
    addUserToPromo,
    errors: {
      userPromotionsError: userPromotionsError?.message,
      userOptinPromotionsError: userOptinPromotionsError?.message
    },
    checkRacePromos
  };
};

export default useUserPromos;
