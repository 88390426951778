import type { Item } from "@tvg/design-system/web/components/SearchBar/types";
import type { Actions } from "../actions/horses";

export type State = {
  filteredHorseNames: Item[];
};

export const initialState = {
  filteredHorseNames: []
};

export default function reducer(
  state: State = initialState,
  action = {} as Actions
): State {
  switch (action.type) {
    case "FILTERED_HORSE_NAMES": {
      return {
        ...state,
        filteredHorseNames: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
