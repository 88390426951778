import type { Actions } from "../actions/tracks";

export type State = {
  currentReplay: string | null;
  currentReplayFileName: string | undefined;
  videoTitle: string | undefined;
  onlyFavorites: boolean;
  openTracks: Array<string>;
};

export const initialState = {
  currentReplay: null,
  currentReplayFileName: undefined,
  videoTitle: undefined,
  onlyFavorites: false,
  openTracks: []
};
export default function reducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: State = initialState,
  action: Actions = {} as Actions
): State {
  switch (action.type) {
    case "TOGGLE_TRACK_ROW": {
      return {
        ...state,
        openTracks: action.payload.openTracks
      };
    }
    case "TOGGLE_ONLY_FAVORITES": {
      return {
        ...state,
        onlyFavorites: action.payload.onlyFavorites
      };
    }
    case "SET_CURRENT_REPLAY_VIDEO": {
      return {
        ...state,
        currentReplay: action.payload.currentReplay,
        currentReplayFileName: action.payload.currentReplayFileName,
        videoTitle: action.payload.videoTitle
      };
    }
    default: {
      return state;
    }
  }
}
